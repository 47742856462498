import * as React from "react";

import OrganizationSearch from "./OrganizationSearch";
import OrganizationSearchGus from "./OrganizationSearchGus";
import { useQuery } from "react-query";
import OrganizationsAPI from "../api/OrganizationsAPI";
import { Fragment, useEffect, useState } from "react";
import { AuthError, Organization } from "./OrganizationModel";
import { Link, MessageBar, MessageBarType } from "@fluentui/react";
import { makeStyles, tokens, Tab, TabList, Spinner } from "@fluentui/react-components";
import type { SelectTabData, SelectTabEvent, TabValue } from "@fluentui/react-components";
import OrganizationError from "./OrganizationError";
import { AxiosError } from "axios";
import { useAuthorization } from "../authentication/AuthorizationContext";
import { useUserAsset } from "../userAssets/UserAssetContext";
import OrganizationTutorial from "./OrganizationTutorial";
import { LABELS_I18N } from "../../common/i18n/pl_PL";
import FeatureStatusAPI from "../api/FeatureStatusAPI";
import OrganizationData from "./OrganizationData";
import CRBRData from "./CRBRData";
import WebScraperData from "./Representatives";
import { RepresentativeEnum } from "../api/WebSrcaperAPI";

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    rowGap: "20px",
  },
  panels: {
    minWidth: "100%",
    "& th": {
      textAlign: "left",
    },
  },
  propsTable: {
    "& td:first-child": {
      fontWeight: tokens.fontWeightSemibold,
    },
  },
});

const OrganizationPage = () => {
  const [krs, setKrs] = useState<string>("");
  const [gusStatus, setGusStatus] = useState<number>(1);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [krsFetched, setKrsFetched] = useState<boolean>(false);
  const [queryError, setQueryError] = useState<AxiosError | null>(null);
  const [isDatePickerChecked, setIsDatePickerChecked] = useState<boolean>(false);

  const { isAuthorized, isRefreshTokenValid, logout, isTokenNull, isBetaTester } = useAuthorization();
  const { clearUserAsset, setUserAssets } = useUserAsset();
  const [authError, setAuthError] = useState<AuthError | null>(null);

  const { isLoading, isRefetching, refetch, error, data } = useQuery(
    "organization",
    (): Promise<Organization> => OrganizationsAPI.getOrganization(krs, selectedDate),
    {
      refetchOnWindowFocus: false,
      enabled: false, // (!) handle refetchs manually
      keepPreviousData: false,
      retry: 0,
    }
  );

  const styles = useStyles();
  const [selectedValue, setSelectedValue] = React.useState<TabValue>("organization");
  const onTabSelect = (_event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  useEffect(() => {
    if (krsFetched) {
      setQueryError(error as AxiosError);
    }
  }, [error]);

  useEffect(() => {
    if (!isAuthorized) {
      setKrs("");
      setKrsFetched(false);
      setSelectedDate(undefined);
      clearUserAsset();
    } else {
      FeatureStatusAPI.getStatus()
        .then((response) => {
          return {
            data: response.json(),
            status: response.status,
          };
        })
        .then((res) => {
          if (res.status !== 200) {
            setGusStatus(0);
          } else {
            res.data.then((gs) => {
              setGusStatus(gs.gusStatus);
            });
          }
        });
      setUserAssets();
    }
  }, [isAuthorized]);

  const handleSearch = async (krs: string, date?: Date) => {
    await setKrs(krs);
    await setSelectedDate(date);
    // eslint-disable-next-line no-undef
    if (krs) {
      setKrsFetched(true);
      if (isRefreshTokenValid()) {
        setAuthError(null);
        refetch();
      } else {
        if (isTokenNull()) {
          setAuthError({
            response: {
              status: 101,
            },
          });
        } else {
          setAuthError({
            response: {
              status: 201,
            },
          });
          logout();
        }
      }
    }
  };

  const handleRegisterSwitch = async () => {
    await setKrsFetched(false);
    await setKrs("");
  };

  const handleDatePickerStateChange = (checked) => {
    setKrsFetched(false);
    setIsDatePickerChecked(checked === true);
  };

  return (
    // @ts-ignore
    <Fragment>
      {!(gusStatus == 1) ? (
        <OrganizationSearch
          onSearch={handleSearch}
          onRegisterSwitch={handleRegisterSwitch}
          onDatePickerStateChange={handleDatePickerStateChange}
          query={krs}
          queryDate={selectedDate}
          disabled={isLoading || isRefetching}
        />
      ) : (
        <OrganizationSearchGus
          onSearch={handleSearch}
          onDatePickerStateChange={handleDatePickerStateChange}
          query={krs}
          queryDate={selectedDate}
          disabled={isLoading || isRefetching}
        />
      )}
      {(isLoading || isRefetching) && <Spinner />}
      {!(isLoading || isRefetching) && (queryError || authError) && (
        <OrganizationError error={authError || (queryError as AxiosError)} />
      )}
      {!isAuthorized && <OrganizationTutorial />}
      {isAuthorized && (
        <Fragment>
          {!(isLoading || isRefetching || error) && krsFetched && data?.dataIntegrityConcernFlag && (
            <MessageBar messageBarType={MessageBarType.warning}>
              <br /> {LABELS_I18N.organizationPage.messageBar.dataIntegrityConcernText.part1}
              <Link rel="noopener noreferrer" href="https://wyszukiwarka-krs.ms.gov.pl/" target="_blank">
                {LABELS_I18N.organizationPage.messageBar.dataIntegrityConcernText.part2}
              </Link>
            </MessageBar>
          )}
        </Fragment>
      )}

      {isAuthorized && krsFetched && !(isLoading || isRefetching || error) && (
        <div className={styles.root}>
          <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
            <Tab id="organization" value="organization">
              {LABELS_I18N.organizationPage.tabs.org}
            </Tab>
            {isBetaTester && data?.krs && (
              <Tab id="reps" value="reps">
                {LABELS_I18N.organizationPage.tabs.reps}
              </Tab>
            )}
            {data?.krs && (
              <Tab id="crbr" value="crbr">
                {LABELS_I18N.organizationPage.tabs.crbr}
              </Tab>
            )}
          </TabList>
          <div className={styles.panels}>
            {selectedValue === "organization" && <OrganizationData organization={data} />}
            {selectedValue === "reps" && (
              <WebScraperData
                id={data.krs}
                type={
                  data.companyRegisterFlag
                    ? RepresentativeEnum[RepresentativeEnum.enterprise]
                    : RepresentativeEnum[RepresentativeEnum.associacion]
                }
                isDate={isDatePickerChecked}
              />
            )}
            {selectedValue === "crbr" && <CRBRData id={data.krs} />}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default OrganizationPage;
