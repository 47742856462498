import React, { Fragment, useEffect, useState } from "react";
import { AxiosError } from "axios";
import { DefaultButton, Dialog, DialogFooter, Label } from "@fluentui/react";
import { useAuthorization } from "../authentication/AuthorizationContext";
import { AuthError, ErrData } from "./OrganizationModel";
import { LABELS_I18N } from "../../common/i18n/pl_PL";

interface SearchProps {
  error: AxiosError | AuthError;
}

const OrganizationError: React.FC<SearchProps> = ({ error }) => {
  const ERROR_MESSAGE_CROSSED_OUT = LABELS_I18N.organizationError.CROSSED_OUT;
  const ERROR_MESSAGE_NOT_FOUND = LABELS_I18N.organizationError.NOT_FOUND;
  const ERROR_MESSAGE_CEIDG_NOT_FOUND = LABELS_I18N.organizationError.CEIDG_NOT_FOUND;
  const ERROR_MESSAGE_CRBR_NOT_FOUND = LABELS_I18N.organizationError.CRBR_NOT_FOUND;
  const ERROR_MESSAGE_REPS_NOT_FOUND = LABELS_I18N.organizationError.REPS_NOT_FOUND;
  const ERROR_MESSAGE_AUTH = LABELS_I18N.organizationError.AUTH;
  const ERROR_MESSAGE_DEFAULT = LABELS_I18N.organizationError.DEFAULT;
  const ERROR_MESSAGE_FORBIDDEN = LABELS_I18N.organizationError.FORBIDDEN;
  const ERROR_MESSAGE_SESSION = LABELS_I18N.organizationError.SESSION;
  const ERROR_MESSAGE_DATA_INTEGRITY_ON_DATE = LABELS_I18N.organizationError.DATA_INTEGRITY_ON_DATE;
  const ERROR_MESSAGE_NOT_FOUND_ON_DATE = LABELS_I18N.organizationError.NOT_FOUND_ON_DATE;

  const [message, setMessage] = useState<string>(ERROR_MESSAGE_DEFAULT);
  const [isModal, setIsModal] = useState<boolean>(false);
  const { isAuthorized } = useAuthorization();

  useEffect(() => {
    let errorMessage = ERROR_MESSAGE_DEFAULT;
    let isModalError = false;

    if (error) {
      const code = error.response?.status;
      const errCode = error.response?.data as ErrData;

      switch (code) {
        case 422:
          switch (errCode.code) {
            case 107:
              errorMessage = ERROR_MESSAGE_DATA_INTEGRITY_ON_DATE;
              isModalError = true;
              break;
            case 108:
              errorMessage = ERROR_MESSAGE_NOT_FOUND_ON_DATE;
              isModalError = true;
              break;
            case 105:
              errorMessage = ERROR_MESSAGE_CROSSED_OUT;
              isModalError = true;
              break;
            case 102:
              errorMessage = ERROR_MESSAGE_NOT_FOUND;
              isModalError = true;
              break;
            default:
              errorMessage = ERROR_MESSAGE_DEFAULT;
              isModalError = true;
              break;
          }
          break;
        case 401:
          errorMessage = ERROR_MESSAGE_AUTH;
          isModalError = true;
          break;
        case 101:
          errorMessage = ERROR_MESSAGE_FORBIDDEN;
          isModalError = true;
          break;
        case 201:
          errorMessage = ERROR_MESSAGE_SESSION;
          isModalError = true;
          break;
        case 404:
          switch (errCode.code) {
            case 109:
              errorMessage = ERROR_MESSAGE_CEIDG_NOT_FOUND;
              isModalError = true;
              break;
            case 113:
              errorMessage = ERROR_MESSAGE_CRBR_NOT_FOUND;
              isModalError = false;
              break;
            case 114:
              errorMessage = ERROR_MESSAGE_REPS_NOT_FOUND;
              isModalError = false;
              break;
            default:
              errorMessage = ERROR_MESSAGE_NOT_FOUND;
              isModalError = true;
              break;
          }
          break;
        case 403:
          errorMessage = ERROR_MESSAGE_FORBIDDEN;
          isModalError = true;
          break;
        default:
          errorMessage = ERROR_MESSAGE_DEFAULT;
          isModalError = true;
          break;
      }
    }

    setMessage(errorMessage);
    setIsModal(isModalError);
  }, [error]);

  return (
    <Fragment>
      {message ? (
        <Fragment>
          {isModal ? (
            <Dialog hidden={false}>
              <Label className="ms-fontSize-14">{message}</Label>
              <DialogFooter>
                <DefaultButton text="Ok" onClick={() => setMessage("")} />
              </DialogFooter>
            </Dialog>
          ) : (
            <Fragment>{isAuthorized && <div className="ms-fontColor-sharedRed20">{message}</div>}</Fragment>
          )}
        </Fragment>
      ) : (
        <Fragment></Fragment>
      )}
    </Fragment>
  );
};

export default OrganizationError;
