import { IDatePickerStrings } from "@fluentui/react";

export const i18nDatePicker: IDatePickerStrings = {
  months: [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień",
  ],

  shortMonths: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"],

  days: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],

  shortDays: ["Nie", "Pon", "Wt", "Śr", "Czw", "Pt", "Sob"],

  goToToday: "Dziś",
  prevMonthAriaLabel: "Poprzedni miesiąc",
  nextMonthAriaLabel: "Następny miesiąc",
  prevYearAriaLabel: "Poprzedni rok",
  nextYearAriaLabel: "Następny rok",
};

export const LABELS_I18N = {
  general: {
    save: "Zapisz",
    create: "Utwórz",
    cancel: "Anuluj",
    submit: "Wyślij",
    close: "Zamknij",
    delete: "Usuń",
    ups: "Przykro nam, powstał chwilowy problem z działaniem narzędzia. Spróbuj za chwilę.",
  },
  error: {
    collection: "Nie można pobrać danych kolekcji.",
    collectionUpdate: "Nie można zmienić danych kolekcji.",
    collectionTemplateUpdate: "Nie można zmienić wzorca komparycji.",
    group: "Nie można pobrać danych grupy.",
    email: "Niepoprawny format e-mail",
  },
  organizationError: {
    CROSSED_OUT: "Podmiot został wykreślony z KRS",
    NOT_FOUND: "Podmiot nie został znaleziony w rejestrze",
    CEIDG_NOT_FOUND: "Nie odnaleziono osby fizycznej o podanym NIP lub podano NIP spółki cywilnej",
    CRBR_NOT_FOUND: "Nie odnaleziono Beneficjentów rzeczywistych w rejestrze CRBR dla podanego podmiotu",
    REPS_NOT_FOUND: "Nie wskazano Reprezentatnów w rejestrze KRS dla podanego podmiotu",
    AUTH: "Zła nazwa użytkownika lub błędne hasło",
    DEFAULT: "Nieznany błąd",
    FORBIDDEN: "Brak wykupionej subskrypcji. Skontatktuj się z nami przez email w menu kontakt.",
    SESSION: "Za długa sesja, proszę zaloguj się ponownie",
    DATA_INTEGRITY_ON_DATE: "Brak historii wpisów KRS. Możliwe tylko pobranie aktualnego wpisu.",
    NOT_FOUND_ON_DATE: "W zadanym dniu podmiot nie wystęuje w rejestrze, albo został wcześniej wykreślony",
  },
  organizationPage: {
    dropdown: {
      placeholder: "Wybierz kolekcję",
      dropdownOptions: {
        pickedHeader: "Wybrana kolekcja",
        availableHeader: "Dostępne kolekcje",
      },
    },
    messageBar: {
      dataIntegrityConcernText: {
        part1: "Wykryliśmy problemy z danymi po stronie API KRS. Sprawdź wynik z",
        part2: " internetową wyszukiwarką podmiotów KRS",
      },
    },
    primaryButton: {
      text: "Wstaw komparycję",
    },
    tabs: {
      org: "Podmiot",
      crbr: "Beneficjenci",
      reps: "Zarząd",
    },
  },
  organizationData: {
    rowLabels: {
      krs: "KRS",
      regon: "REGON",
      nip: "NIP",
      name: "Nazwa",
      address: "Adres",
      courtOfLaw: "Sąd rejestrowy",
      wayOfRepresentation: "Sposób reprezentacji",
      source: "Rejestr",
      headquater: "Siedziba",
    },
  },
  CRBRData: {
    rowLabels: {
      person: "Imię i nazwisko",
      pesel: "PESEL",
      citizenship: "Obywatelstwo",
    },
  },
  WebScraperData: {
    progressBarLabel: "Pobieranie listy reprezentatów",
    disclaimerLabel: "Lista reprezentantów w wersji beta nie zawiera prokurentów lub pełnomocników",
    datePickerDisclaimerLabel: "W wersji beta lista członków zarządu jest dostępna tylko dla odpisu aktualnego",
    rowLabels: {
      person: "Imię i nazwisko",
      pesel: "PESEL",
      function: "Funkcja",
    },
  },
  organizationSearch: {
    toolTip: {
      date: "Wyszukuje ostatni odpis sprzed podanej daty lub odpis aktualny jeżeli data nie została wybrana",
      registryPicker:
        "Wyszukuje podmiot we wskazanym rejestrze. W przypadku KRS należy podać nr KRS podmiotu. W przypadku CEIDG należy podać nr NIP osoby fizycznej",
    },
    textField: {
      placeholder: {
        part1: "wprowadź numer nip",
        part2: "wprowadź numer krs",
        part3: "wprowadź numer nip, regon lub krs",
      },
    },
    krsErrorMessage: "Niepoprawny numer",
    datePicker: {
      ariaLabel: "Wybierz datę",
    },
    primaryButton: {
      text: "Wyszukaj",
    },
  },
  managementPage: {
    title: "Ustawienia kolekcji",
    deleteMessage: {
      default: "Usunięcie oznacza rezygnację z korzystania z kolekcji. Wpływa to tylko na Twoje konto",
      variant: "Całkowite usunięcie kolekcji z bazy oraz usunięcie kolekcji z kont zaproszonych współpracowników",
    },
    dataGrid: {
      collectionNameHeaderCell: "Nazwa kolekcji",
      collectionLabelHeaderCell: "Opis kolekcji",
      collectionMemberCountHeaderCell: "Liczba użytkowników",
      collectionActionsHeaderCell: "Akcje",
    },
    newCollectionForm: {
      collectionNameLabel: "Nazwa kolekcji",
      collectionLabelLabel: "Opis kolekcji",
      maxStringLength: "Maksymalna ilość znaków to",
      collectionNameIsRequiredErrorMessage: "Nazwa kolekcji jest wymagana",
    },
  },
  headerComponent: {
    signInButtonLabel: "Zaloguj",
    signOutButtonLabel: "Wyloguj",
    sideMenu: {
      title: "Menu",
      aboutLabel: "O nas",
      contactLabel: "Kontakt",
      collectionManagementLabel: "Ustawienia kolekcji",
    },
  },
  collectionEditComponent: {
    collectionNameLabel: "Nazwa kolekcji",
    collectionLabelLabel: "Opis kolekcji",
    collectionTemplateNameDropdownLabel: "Wzór komparycji",
    collectionTemplateTextLabel: "Edytujesz wzór",
    collectionTemplateUnknownTag: "Nieznany tag",
    dropdown: {
      groupPartnership: "Spółki",
      groupAssociacion: "Stowarzyszenia",
      groupCooperative: "Spółdzielnie",
      groupOther: "Inne",
    },
  },
  collectionInviteComponent: {
    inviteEmailLabel: "Adresy email zapraszanych osób",
  },
};

export const LEGAL_FORM_MAP = new Map();
LEGAL_FORM_MAP.set("sp_j", "SPÓŁKA JAWNA");
LEGAL_FORM_MAP.set("sp_zoo", "SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ");
LEGAL_FORM_MAP.set("sp_k", "SPÓŁKA KOMANDYTOWA");
LEGAL_FORM_MAP.set("sp_p", "SPÓŁKA PARTNERSKA");
LEGAL_FORM_MAP.set("sp_ka", "SPÓŁKA KOMANDYTOWO-AKCYJNA");
LEGAL_FORM_MAP.set("sp_pakc", "PROSTA SPÓŁKA AKCYJNA");
LEGAL_FORM_MAP.set("sp_akc", "SPÓŁKA AKCYJNA");
LEGAL_FORM_MAP.set("ezig", "EUROPEJSKIE ZGRUPOWANIE INTERESÓW GOSPODARCZYCH");
LEGAL_FORM_MAP.set("sp_eu", "SPÓŁKA EUROPEJSKA");
LEGAL_FORM_MAP.set("spol", "SPÓŁDZIELNIA");
LEGAL_FORM_MAP.set("spol_eu", "SPÓŁDZIELNIA EUROPEJSKA");
LEGAL_FORM_MAP.set("prze_pa", "PRZEDSIĘBIORSTWO PAŃSTWOWE");
LEGAL_FORM_MAP.set("fund", "FUNDACJA");
LEGAL_FORM_MAP.set("stow", "STOWARZYSZENIE");
LEGAL_FORM_MAP.set("zw", "ZWIĄZEK STOWARZYSZEŃ");
LEGAL_FORM_MAP.set("zw_ogr", "ZWIĄZEK STOWARZYSZEŃ OGRODOWYCH");
LEGAL_FORM_MAP.set("stow_ogr", "STOWARZYSZENIE OGRODOWE");
LEGAL_FORM_MAP.set("inol_Łu", "INSTYTUT BADAWCZY");
LEGAL_FORM_MAP.set("inol_lu", "INSTYTUT BADAWCZY / INSTYTUT DZIAŁAJĄCY W RAMACH SIECI BADAWCZEJ ŁUKASIEWICZ");
LEGAL_FORM_MAP.set("tow_wz", "TOWARZYSTWO UBEZPIECZEŃ WZAJEMNYCH");
LEGAL_FORM_MAP.set("tow_re", "TOWARZYSTWO REASEKURACJI WZAJEMNEJ");
LEGAL_FORM_MAP.set(
  "opz_rp",
  "ODDZIAŁ PRZEDSIĘBIORTWA ZAGRANICZNEGO DZIAŁAJĄCY NA TERYTORIUM RZECZYPOSPOLITEJ POLSKIEJ"
);
LEGAL_FORM_MAP.set("goz_zu", "GŁÓWNY ODDZIAŁ ZAGRANICZNEGO ZAKŁADU UBEZPIECZEŃ");
LEGAL_FORM_MAP.set("goz_zr", "GŁÓWNY ODDZIAŁ ZAGRANICZNEGO ZAKŁADU REASEKURACJI");
LEGAL_FORM_MAP.set("igb", "INSTYTUCJA GOSPODARKI BUDŻETOWEJ");
LEGAL_FORM_MAP.set("spzoz", "SAMODZIELNY PUBLICZNY ZAKŁAD OPIEKI ZDROWOTNEJ");
LEGAL_FORM_MAP.set("kr", "KÓŁKO ROLNICZE");
LEGAL_FORM_MAP.set("rzb", "ROLNICZE ZRZESZENIE BRANŻOWE");
LEGAL_FORM_MAP.set("zrzb", "ZWIĄZEK ROLNICZYCH ZRZESZEŃ BRANŻOWYCH");
LEGAL_FORM_MAP.set("zrkor", "ZWIĄZKI ROLNIKÓW, KÓŁEK I ORGANIZACJI ROLNICZYCH");
LEGAL_FORM_MAP.set("zzri", "ZWIĄZEK ZAWODOWY ROLNIKÓW INDYWIDUALNYCH");
LEGAL_FORM_MAP.set("cerz", "CECH RZEMIEŚLNICZY");
LEGAL_FORM_MAP.set("izrz", "IZBA RZEMIEŚLNICZA");
LEGAL_FORM_MAP.set("zrp", "ZWIĄZEK RZEMIOSŁA POLSKIEGO");
LEGAL_FORM_MAP.set("zhu", "ZRZESZENIE HANDLU I USŁUG");
LEGAL_FORM_MAP.set("zrt", "ZRZESZENIE TRANSPORTU");
LEGAL_FORM_MAP.set("orzhu", "OGÓLNOKRAJOWA REPREZENTACJA ZRZESZEŃ HANDLU I USŁUG");
LEGAL_FORM_MAP.set("orzt", "OGÓLNOKRAJOWA REPREZENTACJA ZRZESZEŃ TRANSPORTU");
LEGAL_FORM_MAP.set("iopg", "INNA ORGANIZACJA PODMIOTÓW GOSPODARCZYCH");
LEGAL_FORM_MAP.set("izgo", "IZBA GOSPODARCZA");
LEGAL_FORM_MAP.set("zwza", "ZWIĄZEK ZAWODOWY");
LEGAL_FORM_MAP.set("jozzpop", "JEDNOSTKA ORGANIZACYJNA ZWIĄZKU ZAWODOWEGO POSIADAJĄCA OSOBOWOŚĆ PRAWNĄ");
LEGAL_FORM_MAP.set("ozwm", "OGÓLNOKRAJOWY ZWIĄZEK MIĘDZYBRANŻOWY");
LEGAL_FORM_MAP.set("ozrm", "OGÓLNOKRAJOWE ZRZESZENIE MIĘDZYBRANŻOWE");
LEGAL_FORM_MAP.set("zwpr", "ZWIĄZEK PRACODAWCÓW");
LEGAL_FORM_MAP.set("fkzp", "FEDERACJA / KONFEDERACJA ZWIĄZKÓW PRACODAWCÓW");
LEGAL_FORM_MAP.set("zwsp", "ZWIĄZEK SPORTOWY");
LEGAL_FORM_MAP.set("pzwsp", "POLSKI ZWIĄZEK SPORTOWY");
LEGAL_FORM_MAP.set("iosz", "INNA ORGANIZACJA SPOŁECZNA LUB ZAWODOWA");
LEGAL_FORM_MAP.set("nopopp", "NIE POSIADAJĄCA OSOBOWOŚCI PRAWNEJ ORGANIZACJA POŻYTKU PUBLICZNEGO");
LEGAL_FORM_MAP.set("iopopp", "INNA OSOBA PRAWNA BĘDĄCA ORGANIZACJĄ POŻYTKU PUBLICZNEGO");
LEGAL_FORM_MAP.set("kopopp", "KOŚCIELNA OSOBA PRAWNA BĘDĄCA ORGANIZACJĄ POŻYTKU PUBLICZNEGO");
LEGAL_FORM_MAP.set(
  "nopkopp",
  "NIEPOSIADAJĄCA OSOBOWOŚCI PRAWNEJ INSTYTUCJA KOŚCIELNA BĘDĄCA ORGANIZACJĄ POŻYTKU PUBLICZNEGO"
);
LEGAL_FORM_MAP.set("skf", "STOWARZYSZENIE KULTURY FIZYCZNEJ");
LEGAL_FORM_MAP.set("inne", "ODDZIAŁ ZAGRANICZNEGO PRZEDSIĘBIORCY");
LEGAL_FORM_MAP.set("jtspop", "JEDNOSTKA TERENOWA STOWARZYSZENIA POSIADAJĄCA OSOBOWOŚĆ PRAWNĄ");
LEGAL_FORM_MAP.set("jdg", "JEDNOOSOBOWA DZIAŁALNOŚĆ GOSPODARCZA");
