import * as React from "react";

import { Organization } from "./OrganizationModel";
import { TableBody, TableCell, TableRow, Table, TableHeader } from "@fluentui/react-components";
import { LABELS_I18N } from "../../common/i18n/pl_PL";
import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
  IDropdownStyles,
  IStackTokens,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { useAuthorization } from "../authentication/AuthorizationContext";
import { CollectionInstanceRequest, Collections } from "../userAssets/CollectionModel";
import CollectionAPI from "../api/CollectionAPI";
import { useUserAsset } from "../userAssets/UserAssetContext";

interface OrgTabProps {
  organization: Organization;
}
interface DropdownOptionItem {
  key: string;
  text: string;
}
interface DropdownOptionItems extends Array<DropdownOptionItem> {}
interface Item {
  key: string;
  value: string;
}
const itemTokens = [
  "name",
  "krs",
  "nip",
  "regon",
  "headquater",
  "address",
  "courtOfLaw",
  "wayOfRepresentation",
  "source",
];

const transformations = {
  address: (obj: any) => {
    return `${obj?.postalCode} ${obj?.city}, ${obj?.street ? obj?.street : obj?.city} ${obj?.streetNumber} ${
      obj?.apartment ? "lok. " + obj?.apartment : ""
    }`;
  },
  headquaterKRS: (obj: any) => {
    return `${obj?.voivodeship}, ${obj?.community}`;
  },
  headquaterCEIDG: (obj: any) => {
    return `${obj?.postalCode} ${obj?.city}, ${obj?.street ? obj?.street : obj?.city} ${obj?.streetNumber} ${
      obj?.apartment ? "lok. " + obj?.apartment : ""
    }`;
  },
  default: (str: string) => {
    return str;
  },
};

function transformToItems(organization: Organization): Item[] {
  let map = [];
  if (organization != null) {
    itemTokens.forEach((item) => {
      let t: { key: string; value: string };
      let strategy = item + organization.source;
      switch (item) {
        case "address":
          t = { key: LABELS_I18N.organizationData.rowLabels[item], value: transformations.address(organization[item]) };
          break;
        case "headquater":
          t = {
            key: LABELS_I18N.organizationData.rowLabels[item],
            value: transformations[strategy](organization[item]),
          };
          break;
        default:
          t = { key: LABELS_I18N.organizationData.rowLabels[item], value: transformations.default(organization[item]) };
          break;
      }
      if (!(t.value == null || typeof t.value == "undefined")) {
        map.push(t);
      }
    });
  }
  return map;
}

const dropdownTokens: IStackTokens = {
  childrenGap: 8,
  maxWidth: "200px",
};
const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 150 },
};

function getDropdownLabels(collections: Collections): DropdownOptionItems {
  let result: DropdownOptionItems = [];
  if (collections) {
    result = collections.flatMap((item) => {
      return {
        key: item.id,
        text: item.metadata.uiprops.label ? item.metadata.uiprops.label : item.metadata.collectionName,
      };
    });
  }
  return result;
}

const OrganizationData: React.FC<OrgTabProps> = ({ organization }) => {
  const { isAuthorized } = useAuthorization();
  const { pickedCollection, collections, populatePickedCollection } = useUserAsset();

  const options: IDropdownOption[] = [
    {
      key: "pickedHeader",
      text: LABELS_I18N.organizationPage.dropdown.dropdownOptions.pickedHeader,
      itemType: DropdownMenuItemType.Header,
    },
    {
      key: pickedCollection?.id,
      text: pickedCollection?.metadata.uiprops.label || pickedCollection?.metadata.collectionName,
    },
    {
      key: "availableHeader",
      text: LABELS_I18N.organizationPage.dropdown.dropdownOptions.availableHeader,
      itemType: DropdownMenuItemType.Header,
    },
    ...getDropdownLabels(collections),
  ];

  const handleInsertParagraph = async (data) => {
    let requestData: CollectionInstanceRequest = {
      parameters: {
        companyName: data.name,
        nipId: data.nip,
        krsId: data.krs,
        regonId: data.regon,
        companyAddressCity: data.address.city,
        companyHeadquaterCity: data.address.city,
        companyAddressCountry: data.address.country,
        companyAddressStreet: data.address.street,
        companyAddressStreetNumber: data.address.streetNumber,
        companyAddressApartment: data.address.apartment,
        companyAddressPostalCode: data.address.postalCode,
        courtOfLaw: data.courtOfLaw,
        companyFund: data.companyFund,
        companyFundCurrency: data.companyFundCurrency,
        companyFundPaid: data.companyFundPaid,
        companyFundPaidCurrency: data.companyFundPaidCurrency,
        companyRegisterFlag: data.companyRegisterFlag,
        companyOwnerFirstName: data.ownerFirstName,
        companyOwnerLastName: data.ownerLastName,
      },
    };

    let paragraphInsertData = await CollectionAPI.createCollectionTemplateInstance(
      requestData,
      pickedCollection.id,
      data.templateName
    )
      .then((response) => {
        return {
          data: response.json(),
          status: response.status,
        };
      })
      .then((res) => {
        if (res.status !== 201) {
          throw new Error("Could not fetch collection template instance");
        } else {
          return res.data.then((templateInstance) => {
            return templateInstance;
          });
        }
      });
    // eslint-disable-next-line no-undef
    await Word.run(async (context) => {
      let body = context.document.getSelection();
      // eslint-disable-next-line no-undef
      body.insertText(paragraphInsertData?.rawText.run, Word.InsertLocation.after);
      await context.sync();
    });
  };
  const handlePickedCollection = (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
    populatePickedCollection(item.key as string);
  };

  const items = transformToItems(organization);
  return (
    <React.Fragment>
      <Table style={{ maxWidth: "100%" }}>
        <TableHeader />
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.key}>
              <TableCell>{item.key}</TableCell>
              <TableCell>{item.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {isAuthorized && (
        <React.Fragment>
          <div className="blink-actions">
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={dropdownTokens}>
              <Stack.Item>
                <PrimaryButton
                  text={LABELS_I18N.organizationPage.primaryButton.text}
                  onClick={() => handleInsertParagraph(organization)}
                />
              </Stack.Item>
              <Stack.Item>
                <Dropdown
                  placeholder={LABELS_I18N.organizationPage.dropdown.placeholder}
                  options={options}
                  styles={dropdownStyles}
                  onChange={handlePickedCollection}
                />
              </Stack.Item>
            </Stack>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default OrganizationData;
