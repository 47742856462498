import * as React from "react";

import { TableBody, TableCell, TableRow, Table, TableHeader, TableHeaderCell } from "@fluentui/react-components";
import { LABELS_I18N } from "../../common/i18n/pl_PL";
import WebScraperAPI from "../api/WebSrcaperAPI";
import { useEffect } from "react";
import OrganizationError from "./OrganizationError";
import { AuthError } from "./OrganizationModel";
import { ProgressIndicator } from "@fluentui/react/lib/ProgressIndicator";
import { Label } from "@fluentui/react";

interface WebScraperTabProps {
  id: string;
  type: string;
  isDate: boolean;
}

interface WebScraperResponse {
  firstName: string;
  middleName?: string;
  lastName: string;
  middleLastName?: string;
  function?: string;
}

const transformations = {
  person: (obj: WebScraperResponse) => {
    return `${obj?.firstName}${obj?.middleName ? " " + obj?.middleName : ""} ${obj?.lastName}`;
  },
  default: (str: string) => {
    return str;
  },
};

const intervalDelay = 100;
const intervalIncrement = 0.01;

const WebScraperData: React.FC<WebScraperTabProps> = ({ id, type, isDate }) => {
  const [isLoading, setIsLoading] = React.useState<Boolean>(false);
  const [repsData, setRepsData] = React.useState<WebScraperResponse[]>(null);
  const [errorMessage, setErrorMessage] = React.useState<AuthError>(null);
  const [percentComplete, setPercentComplete] = React.useState(0);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const id = setInterval(() => {
      setPercentComplete((intervalIncrement + percentComplete) % 1);
    }, intervalDelay);
    return () => {
      // eslint-disable-next-line no-undef
      clearInterval(id);
    };
  });

  useEffect(() => {
    setIsLoading(true);
    WebScraperAPI.getRepresentatives(id, type)
      .then((response) => {
        return {
          data: response.json(),
          status: response.status,
        };
      })
      .then((res) => {
        if (res.status !== 200) {
          res.data.then((r) => {
            setErrorMessage({
              response: {
                status: res.status,
                data: {
                  code: r.code,
                  message: r.message,
                },
              },
            });
          });
          setRepsData(null);
          setIsLoading(false);
        } else {
          res.data.then((data) => {
            if (data.length != 0) {
              setRepsData(data);
              setIsLoading(false);
              setErrorMessage(null);
            } else {
              setRepsData(null);
              setIsLoading(false);
              setErrorMessage({
                response: {
                  status: 404,
                  data: {
                    code: 114,
                    message: "",
                  },
                },
              });
            }
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setRepsData(null);
        setErrorMessage({
          response: {
            status: 500,
            data: {
              code: 500,
              message: JSON.stringify(error),
            },
          },
        });
      });
  }, []);

  const columns = [
    { columnKey: "person", label: LABELS_I18N.WebScraperData.rowLabels.person },
    { columnKey: "function", label: LABELS_I18N.WebScraperData.rowLabels.function },
  ];

  const items = repsData;
  return (
    <React.Fragment>
      {isLoading && (
        <ProgressIndicator label={LABELS_I18N.WebScraperData.progressBarLabel} percentComplete={percentComplete} />
      )}
      {repsData != null && errorMessage == null && isDate == false && (
        <Table style={{ maxWidth: "100%" }}>
          <TableHeader>
            <TableRow>
              {columns.map((column) => (
                <TableHeaderCell key={column.columnKey}>{column.label}</TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {items.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell>{transformations.person(item)}</TableCell>
                <TableCell>{transformations.default(item.function)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {errorMessage != null && <OrganizationError error={errorMessage} />}
      {isDate == true && errorMessage == null && (
        <Label styles={{ root: { fontSize: "x-small", paddingTop: "30px" } }}>
          {LABELS_I18N.WebScraperData.datePickerDisclaimerLabel}
        </Label>
      )}
      {isDate == false && (
        <Label styles={{ root: { fontSize: "x-small", paddingTop: "30px" } }}>
          * {LABELS_I18N.WebScraperData.disclaimerLabel}
        </Label>
      )}
    </React.Fragment>
  );
};

export default WebScraperData;
