export enum RepresentativeEnum {
  enterprise,
  associacion,
}
const WebScraperAPI = {
  baseURL: "https://api.justblink.com.pl",
  //baseURL: "http://localhost:3002",

  getRepresentatives: (id: string, type: string): Promise<Response> => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem("token");

    // eslint-disable-next-line no-undef
    return fetch(`${WebScraperAPI.baseURL}/web-scraper/${id}?${new URLSearchParams({ type: type.toString() })}`, {
      headers: {
        Accept: "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    });
  },
  getActualCopy: (id: string, type: string): Promise<Response> => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem("token");

    // eslint-disable-next-line no-undef
    return fetch(
      `${WebScraperAPI.baseURL}/web-scraper/actual-copy/${id}?${new URLSearchParams({ type: type.toString() })}`,
      {
        headers: {
          Accept: "application/json; charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      }
    );
  },
  getFullCopy: (id: string, type: string): Promise<Response> => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem("token");

    // eslint-disable-next-line no-undef
    return fetch(
      `${WebScraperAPI.baseURL}/web-scraper/full-copy/${id}?${new URLSearchParams({ type: type.toString() })}`,
      {
        headers: {
          Accept: "application/json; charset=utf-8",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      }
    );
  },
};

export default WebScraperAPI;
